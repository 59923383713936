var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c("Layout", {
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [_c("div", { staticClass: "aside" }, [_c("SiteAside")], 1)]
            },
            proxy: true,
          },
          {
            key: "main",
            fn: function () {
              return [
                _c("div", { staticClass: "main" }, [
                  _c("div", { staticClass: "header" }, [_c("Header")], 1),
                  _c(
                    "div",
                    { ref: "contentBox", staticClass: "content" },
                    [_c("RouterView")],
                    1
                  ),
                  _c("div", { staticClass: "foot-logo" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/05-Panda/panda-help.svg"),
                        alt: "",
                      },
                    }),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }