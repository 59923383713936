<template>
  <div class="site-aside-container">
    <!-- logo -->
    <div class="logo">
      <div class="logo-area" @click="$router.push({ path: '/dashboard/home' })">
        <img
          @mouseenter="logoIsHover = true"
          @mouseleave="logoIsHover = false"
          :src="
            require(`@/assets/img/05-Panda/nihaokids-logo` +
              (logoIsHover == true ? '-hover' : '') +
              `.svg`)
          "
          title="nihaoKids"
        />
      </div>
    </div>

    <!-- 家庭成员 -->
    <div class="family-merber">
      <div class="family-merber-area">
        <div
          class="family-merber-single"
          v-for="(item, index) in kidsMember"
          :key="index"
          :class="{ activeKid: curretnKidIndex === index }"
        >
          <div
            class="family-merber-avatar"
            @click="chooseWhichKid(item, index)"
          >
            <img :src="item.photo" alt="" />
          </div>
          <div class="family-merber-name font-menu-small">
            {{ item.firstName }}
          </div>
        </div>

        <!-- <div class="family-merber-add">
          <img
            src="@/assets/img/19-Dashboard/icon-add-blue.svg"
            alt=""
            @click="
              $router.push({ path: '/dashboard/profile', query: { id: '1' } })
            "
          />
        </div> -->
      </div>
    </div>

    <!-- 菜单 -->
    <div class="menu">
      <div class="menu-list font-menu-large">
        <div class="menu-item" v-for="item in menuList" :key="item.id">
          <router-link :to="item.url">{{ item.name }}</router-link>
        </div>
      </div>

      <!-- 网络测试 -->
      <div class="test-net" @click="openCheckMask">
        <img src="@/assets/img/19-Dashboard/icon-mic-small.svg" alt="" />
        <div>DEVICE CHECK</div>
      </div>
    </div>
    <div class="device-check-mask " v-if="isOpenDeviceCheck">
      <div class="mask-content scaleToAppear">
        <DeviceCheck @close="closeCheckMask" />
      </div>
    </div>
  </div>
</template>
<script>
import DeviceCheck from "./DeviceCheck";

export default {
  components: {
    DeviceCheck,
  },
  data() {
    return {
      menuList: [
        {
          id: 1,
          name: "Home",
          url: "/dashboard/home",
        },
        {
          id: 2,
          name: "Book a Class",
          url: "/dashboard/bookclass",
        },
        // {
        //   id: 3,
        //   name: "Schedule",
        //   url: "/dashboard/schedule",
        // },
        {
          id: 4,
          name: "Review",
          url: "/dashboard/review",
        },
        // {
        //   id: 5,
        //   name: "Refer a Friend",
        //   url: "/dashboard/referafriend",
        // },
      ],
      logoIsHover: false,
      kidsMember: [],
      userInfo: {},
      curretnKidIndex: 0,
      isOpenDeviceCheck: false,
    };
  },
  created() {
    // console.log(this.$store.state.dashboard.isKid);
    if (this.$store.state.dashboard.isKid) {
      const { userSetting } = JSON.parse(localStorage.getItem("userData"));
      this.kidsMember.push(userSetting);
    } else {
      const { familyMember } = JSON.parse(localStorage.getItem("userData"));
      const { userSetting } = JSON.parse(localStorage.getItem("userData"));
      this.userId = userSetting.userId;
      // console.log(kidsMember);
      this.kidsMember =
        familyMember.length > 0
          ? familyMember.filter((item) => item.isKids)
          : null;
    }
  },
  mounted() {
    // console.log(this.kidsMember && !this.$store.state.dashboard.kidUserId);
    if (this.kidsMember && !this.$store.state.dashboard.kidUserId) {
      this.$store.commit("setKidUserId", this.kidsMember[0].id);
      this.$store.commit("setKidUserIndex", 0);

      this.curretnKidIndex = 0;
    } else {
      this.curretnKidIndex = this.$store.state.dashboard.kidUserIndex;
      // console.log(this.$store.state.dashboard.kidUserIndex);
    }
  },
  computed: {
    kidUserId() {
      return this.$store.state.dashboard.kidUserId;
    },
  },
  methods: {
    chooseWhichKid(item, index) {
      this.curretnKidIndex = index;
      this.$store.commit("setKidUserId", item.id);
      this.$store.commit("setKidUserIndex", index);
    },
    openCheckMask() {
      this.isOpenDeviceCheck = true;
    },
    closeCheckMask() {
      this.isOpenDeviceCheck = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.logo-area {
  cursor: pointer;
}
</style>
