var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-check-container" },
    [
      _c("div", { staticClass: "optionTitle" }, [
        _c("img", {
          staticClass: "optionClose",
          attrs: {
            src: require("@/assets/img/08-Device-check/add_back_blue.svg"),
          },
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        }),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepCode !== 4 || _vm.allSuccess,
              expression: "stepCode !== 4 || allSuccess",
            },
          ],
          staticClass: "switchStep",
        },
        [
          _c("img", {
            staticClass: "stepIcon audio",
            class: {
              status: _vm.audioCode,
              noStatus: !_vm.audioCode && _vm.stepCode == 1,
              scaleToAppear: _vm.showStep1,
              isInvisible: !_vm.showStep1,
              stepIsSemiTransparent: _vm.stepIsSemiTransparent1,
              checkmarkAnim: _vm.stepCode >= 2,
            },
            attrs: { src: _vm.img_step1_title },
          }),
          _c("img", {
            staticClass: "stepIcon camera",
            class: {
              status: _vm.cameraCode == 1 || _vm.cameraCode == 2,
              noStatus:
                (_vm.cameraCode !== 1 || _vm.cameraCode !== 2) &&
                _vm.stepCode == 2,
              scaleToAppear: _vm.showStep2,
              isInvisible: !_vm.showStep2,
              stepIsSemiTransparent: _vm.stepIsSemiTransparent2,
              checkmarkAnim: _vm.stepCode >= 3,
            },
            attrs: { src: _vm.img_step2_title },
          }),
          _c("img", {
            staticClass: "stepIcon microphone",
            class: {
              status: _vm.microphoneCode == 1 || _vm.microphoneCode == 2,
              noStatus:
                (_vm.microphoneCode !== 1 || _vm.microphoneCode !== 2) &&
                _vm.stepCode == 3,
              scaleToAppear: _vm.showStep3,
              isInvisible: !_vm.showStep3,
              stepIsSemiTransparent: _vm.stepIsSemiTransparent3,
              checkmarkAnim: _vm.stepCode >= 4,
            },
            attrs: { src: _vm.img_step3_title },
          }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepCode == 0,
              expression: "stepCode == 0",
            },
          ],
          staticClass: "contentStep stepCode_0",
        },
        [
          _c("img", {
            staticClass: "computer",
            attrs: {
              src: require("@/assets/img/08-Device-check/image-laptop.svg"),
            },
          }),
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "customButton start",
              on: {
                click: function ($event) {
                  return _vm.startCheck()
                },
              },
            },
            [_vm._v("START")]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepCode == 1,
              expression: "stepCode == 1",
            },
          ],
          staticClass: "contentStep stepCode_1",
        },
        [
          _c("AnimateAudioLine", {
            ref: "stepCode_1_audioLine",
            staticClass: "audioLine",
            attrs: {
              audiourl: require("@/assets/img/08-Device-check/test-audio.wav"),
            },
          }),
          _c("p", { staticClass: "des tips-title" }, [
            _vm._v("DID YOU HEAR THE MUSIC"),
          ]),
          _c("div", { staticClass: "buttonGroup font_three" }, [
            _c(
              "div",
              {
                staticClass: "customButton no",
                on: {
                  click: function ($event) {
                    return _vm.stepCode_1_no()
                  },
                },
              },
              [_vm._v("NO")]
            ),
            _c(
              "div",
              {
                staticClass: "customButton yes",
                on: {
                  click: function ($event) {
                    return _vm.stepCode_1_yes()
                  },
                },
              },
              [_vm._v("YES")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepCode == 2,
              expression: "stepCode == 2",
            },
          ],
          staticClass: "contentStep stepCode_2",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cameraCode == 3,
                  expression: "cameraCode == 3",
                },
              ],
              staticClass: "cameraCode3",
            },
            [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "imgGroup" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/08-Device-check/image-camera-access.svg"),
                    },
                  }),
                ]),
              ]),
              _vm.nocamera
                ? _c("div", [
                    _c("p", { staticClass: "tips-title" }, [
                      _vm._v("There was a problem setting up your camera"),
                    ]),
                  ])
                : _c("div", [
                    _c("p", { staticClass: "tips-detail" }, [
                      _vm._v("Allow the access to your camera"),
                    ]),
                    _c("p", { staticClass: "tips-detail" }, [
                      _vm._v(" Please ensure your camera is connected and on "),
                    ]),
                  ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cameraCode == 4,
                  expression: "cameraCode == 4",
                },
              ],
              staticClass: "cameraCode4",
            },
            [
              _c("div", {
                staticClass: "localStreamCont",
                attrs: { id: "local" },
              }),
              _c("div", { staticClass: "switchCamera" }, [
                _c("p", { staticClass: "tips-title" }, [
                  _vm._v("CAN YOU SEE YOURSELF?"),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "tips-detail currentCamera",
                    on: { click: _vm.selectCameraFun },
                  },
                  [
                    _vm._v(" Current camera: "),
                    _c("span", [_vm._v(_vm._s(_vm.selectCamera.value))]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "buttonGroup font_three" }, [
                _c(
                  "div",
                  {
                    staticClass: "customButton no",
                    on: {
                      click: function ($event) {
                        return _vm.stepCode_2_no()
                      },
                    },
                  },
                  [_vm._v("NO")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "customButton yes",
                    on: {
                      click: function ($event) {
                        return _vm.stepCode_2_yes()
                      },
                    },
                  },
                  [_vm._v("YES")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepCode == 3,
              expression: "stepCode == 3",
            },
          ],
          staticClass: "contentStep stepCode_3",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.microphoneCode == 3,
                  expression: "microphoneCode == 3",
                },
              ],
              staticClass: "microphoneCode3",
            },
            [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "imgGroup" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/08-Device-check/image-mic-access.svg"),
                    },
                  }),
                ]),
              ]),
              _vm.nomicrophone
                ? _c("div", [
                    _c("p", { staticClass: "font_four fontWeight_medium" }, [
                      _vm._v(
                        " There was a problem setting up your microphone "
                      ),
                    ]),
                  ])
                : _c("div", [
                    _c("p", { staticClass: "font_four fontWeight_medium" }, [
                      _vm._v(" Allow the access to your mic "),
                    ]),
                    _c("p", {}, [
                      _vm._v("Please ensure your micro is connected and on"),
                    ]),
                  ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.microphoneCode == 4,
                  expression: "microphoneCode == 4",
                },
              ],
              staticClass: "microphoneCode4",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.microphoneAudioUrl,
                      expression: "!microphoneAudioUrl",
                    },
                  ],
                  staticClass: "speak",
                },
                [
                  _c("AnimateSpeak", {
                    ref: "animateSpeak",
                    staticClass: "animateSpeak",
                    on: { trumpMouseup: _vm.trumpMouseup },
                  }),
                  _c("p", { staticClass: "tips-title" }, [
                    _vm._v("SAY SOMETHING TO TEST YOUR MIC"),
                  ]),
                  _c("p", { staticClass: "tips-detail" }, [
                    _vm._v(
                      " Please speak into the microphone for > 5 seconds "
                    ),
                  ]),
                  _c("div", { staticClass: "buttonGroup" }, [
                    _c(
                      "div",
                      {
                        staticClass: "customButton no",
                        on: {
                          click: function ($event) {
                            return _vm.recordAgainFun()
                          },
                        },
                      },
                      [_vm._v(" RECORD AGAIN ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "customButton yes",
                        on: {
                          click: function ($event) {
                            return _vm.recordYes()
                          },
                        },
                      },
                      [_vm._v("DONE")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.microphoneAudioUrl,
                      expression: "microphoneAudioUrl",
                    },
                  ],
                  staticClass: "listen",
                },
                [
                  _c("AnimateAudioLine", {
                    ref: "stepCode_3_audioLine",
                    staticClass: "audioLine",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.stepCode_3_audioLineClick()
                      },
                    },
                  }),
                  _vm._m(1),
                  _c("div", { staticClass: "buttonGroup" }, [
                    _c(
                      "div",
                      {
                        staticClass: "customButton no",
                        on: {
                          click: function ($event) {
                            return _vm.stepCode_3_no()
                          },
                        },
                      },
                      [_vm._v("NO")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "customButton yes",
                        on: {
                          click: function ($event) {
                            return _vm.stepCode_3_yes()
                          },
                        },
                      },
                      [_vm._v("YES")]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepCode == 4,
              expression: "stepCode == 4",
            },
          ],
          staticClass: "contentStep stepCode_4",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.allSuccess,
                  expression: "allSuccess",
                },
              ],
              staticClass: "allSuccess",
            },
            [
              _c("div", { staticClass: "sussess-content" }, [
                _c("img", {
                  staticClass: "icon checkmarkAnim",
                  staticStyle: { margin: "auto", "margin-bottom": "20px" },
                  attrs: {
                    src: require("@/assets/img/08-Device-check/image-success.svg"),
                  },
                }),
                _c(
                  "p",
                  {
                    staticClass: "tips-title",
                    staticStyle: { "margin-bottom": "20px" },
                  },
                  [_vm._v("SETUP COMPLETE")]
                ),
                _vm._m(2),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.allSuccess,
                  expression: "!allSuccess",
                },
              ],
              staticClass: "someError",
            },
            [
              _c("div", { staticClass: "error-content" }, [
                _c("img", {
                  staticClass: "icon checkmarkAnim",
                  staticStyle: { margin: "auto", "margin-bottom": "20px" },
                  attrs: {
                    src: require("@/assets/img/08-Device-check/image-error.svg"),
                  },
                }),
                _c(
                  "p",
                  {
                    staticClass: "tips-title",
                    staticStyle: { "margin-bottom": "10px" },
                  },
                  [_vm._v("Setup Failed")]
                ),
                _c("p", { staticClass: "tips-detail" }, [
                  _vm._v(
                    " Sorry, the setup has failed since not all your devices are not working properly. Please make sure they are turned on and the access is allowed, and try again. "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "errorGroup" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.audioCode !== 1,
                        expression: "audioCode !== 1",
                      },
                    ],
                    staticClass: "errorRow",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/08-Device-check/icon-error-small.svg"),
                      },
                    }),
                    _c("p", { staticClass: "tips-device" }, [
                      _vm._v("Speaker"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.cameraCode !== 1,
                        expression: "cameraCode !== 1",
                      },
                    ],
                    staticClass: "errorRow",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/08-Device-check/icon-error-small.svg"),
                      },
                    }),
                    _c("p", { staticClass: "tips-device" }, [_vm._v("Camera")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.microphoneCode !== 1,
                        expression: "microphoneCode !== 1",
                      },
                    ],
                    staticClass: "errorRow",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/08-Device-check/icon-error-small.svg"),
                      },
                    }),
                    _c("p", { staticClass: "tips-device" }, [
                      _vm._v("Microphone"),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c("NewSelect", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showSelect,
            expression: "showSelect",
          },
        ],
        ref: "newSelect",
        attrs: { listData: _vm.cameraList },
        on: { selectClick: _vm.newSelectClick },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "tips-title" }, [
        _vm._v("TEST YOUR DEVICE BEFORE CLASS"),
      ]),
      _c("p", { staticClass: "tips-detail" }, [
        _vm._v("Speaker, camera and microphone check"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "tips-title" }, [_vm._v("RECORDING AUDIBLE?")]),
      _c("p", { staticClass: "tips-detail" }, [
        _vm._v("Click play above to listen"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tips-detail" }, [
      _c("span", [_vm._v("Your system has been configured successfully.")]),
      _c("br"),
      _c("span", [_vm._v("You can start the class now.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }