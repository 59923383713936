var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-container" }, [
    _c("div", { staticClass: "header-bar font-menu-small" }, [
      _c("div", { staticClass: "course-pakeges" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.goToPackagesPage()
              },
            },
          },
          [_vm._v("Course Packages")]
        ),
      ]),
      _c("div", { staticClass: "area-time" }, [
        _c("span", [_vm._v(_vm._s(_vm.getTime))]),
      ]),
      _c("div", { staticClass: "profile", on: { click: _vm.openSideList } }, [
        _c("div", { staticClass: "profile-avatar" }, [
          _c("img", { attrs: { src: _vm.currentUser.photo, alt: "" } }),
        ]),
        _c("div", { staticClass: "profile-name" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(" " + _vm._s(_vm.currentUser.firstName) + " "),
          ]),
          _vm._m(0),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpenList,
              expression: "isOpenList",
            },
          ],
          staticClass: "profile-area",
          on: { click: _vm.closeList },
        },
        [
          _c(
            "div",
            {
              staticClass: "profile-content",
              on: {
                mouseleave: function ($event) {
                  _vm.isOpenList = false
                },
              },
            },
            [
              _vm._l(_vm.profileList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "profile-item font-menu-medium",
                    on: { click: _vm.closeList },
                  },
                  [
                    _c("router-link", { attrs: { to: item.url } }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "profile-item font-menu-medium",
                  on: { click: _vm.handleLogout },
                },
                [_c("span", { staticClass: "logout" }, [_vm._v("Logout")])]
              ),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/img/02-Toolbar/up-blue.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }