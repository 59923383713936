<template>
  <div class="header-container">
    <div class="header-bar font-menu-small">
      <div class="course-pakeges">
        <span @click="goToPackagesPage()">Course Packages</span>
      </div>
      <!-- <div class="language">
        <span class="active">EN </span>
        <span class="chinese">中文</span>
      </div> -->
      <div class="area-time">
        <span>{{ getTime }}</span>
      </div>
      <div class="profile" @click="openSideList">
        <div class="profile-avatar">
          <img :src="currentUser.photo" alt="" />
        </div>
        <div class="profile-name">
          <div class="name">
            {{ currentUser.firstName }}
          </div>
          <div>
            <img src="@/assets/img/02-Toolbar/up-blue.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="profile-area" v-show="isOpenList" @click="closeList">
        <div class="profile-content" @mouseleave="isOpenList = false">
          <div
            class="profile-item font-menu-medium"
            v-for="(item, index) in profileList"
            :key="index"
            @click="closeList"
          >
            <router-link :to="item.url">{{ item.name }}</router-link>
          </div>
          <div class="profile-item font-menu-medium" @click="handleLogout">
            <span class="logout">Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Header",
  data() {
    return {
      getTime: "",
      profileList: [
        {
          id: 1,
          name: "Profile",
          url: "/dashboard/profile",
        },
        {
          id: 2,
          name: "My Orders",
          url: "/dashboard/myorders",
        },
        // {
        //   id: 3,
        //   name: "Referral Reward",
        //   url: "/dashboard/referralreward",
        // },
        // {
        //   id: 4,
        //   name: "Change Password",
        //   url: "/dashboard/changepassword",
        // },
      ],
      isOpenList: false,
      currentUser: {},
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.getCurrentTime();
    }, 100);

    const { userSetting } = JSON.parse(localStorage.getItem("userData"));

    if (this.$store.state.dashboard.isKid) {
      // 是kid
      this.currentUser = userSetting;
    } else {
      const { familyMember } = JSON.parse(localStorage.getItem("userData"));
      this.patriarch = familyMember.filter((item) => item.isPatriarch);
      this.currentUser = this.patriarch[0];
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    openSideList() {
      // console.log("++");
      this.isOpenList = true;
    },
    closeList() {
      this.isOpenList = false;
    },
    handleLogout() {
      localStorage.removeItem("userData");
      this.$router.push({ name: "LogIn" });
    },
    getCurrentTime() {
      if (
        localStorage.userData &&
        JSON.parse(localStorage.userData).userSetting.timeZone
      ) {
        let timestamp = Date.parse(new Date());
        const useInfo = JSON.parse(localStorage.userData).userSetting;
        const time_zone = JSON.parse(localStorage.userData).userSetting
          .timeZone;
        // let yy = moment(timestamp)
        //   .subtract(moment().utcOffset() / 60 - time_zone, "hours")
        //   .format("YYYY-MM-DD");
        let kk = moment(timestamp)
          .subtract(moment().utcOffset() / 60 - time_zone, "hours")
          .format("kk");

        let mm = moment(timestamp)
          .subtract(moment().utcOffset() / 60 - time_zone, "hours")
          .format("mm");
        let ss = moment(timestamp)
          .subtract(moment().utcOffset() / 60 - time_zone, "hours")
          .format("ss");
        if (kk == 24) {
          kk = 0;
        }
        if (useInfo.timeZoneUtc) {
          useInfo.timeZoneUtc = useInfo.timeZoneUtc.replace("UTC", "GMT");
        }
        this.getTime =
          "UTC/" +
          useInfo.timeZoneUtc +
          " " +
          " | " +
          useInfo.countryNameEn +
          ", " +
          useInfo.timeZoneName +
          " | " +
          " " +
          kk +
          ":" +
          mm +
          ":" +
          ss;
      } else {
        // let yy = new Date().getFullYear();
        // let mm = new Date().getMonth() + 1;
        // let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf =
          new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes();
        let ss =
          new Date().getSeconds() < 10
            ? "0" + new Date().getSeconds()
            : new Date().getSeconds();
        this.getTime =
          "UTC/GMT+8 | China, Beijing | " + hh + ":" + mf + ":" + ss;
      }
    },
    goToPackagesPage() {
      const path = `/dashboard/prices`;
      if (this.$route.path !== path) this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  .header-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    // font-size: 0.8rem;
    position: relative;
    font-weight: 600;

    .course-pakeges {
      // width: 10%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background-color: #f4d737;
      border-radius: 25px;
      margin-right: 2%;
      padding: 0 3%;
      color: #224e96;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: #dec435;
      }
    }

    .language {
      // width: 4%;
      margin-right: 2%;
      color: #9e9e9f;

      span {
        padding-right: 5px;
        cursor: default;

        &:hover,
        &.active {
          color: #224e96;
        }

        &:hover:not(.active) {
          cursor: pointer;
        }
      }
    }

    .area-time {
      // width: 14%;
      height: 36px;
      line-height: 36px;
      margin-right: 2%;
      // background: #f1f1f0;
      border-radius: 25px;
      text-align: center;
      padding: 0 2%;
      color: #224e96;
      cursor: default;
    }

    .profile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      flex-grow: 0;

      .profile-avatar {
        margin-right: 10px;
        width: 100%;
        width: 30px;
        display: flex;
        height: 100%;
        align-items: center;
        flex: 30px 0 0;

        img {
          width: 100%;
          border-radius: 50%;
        }
      }

      .profile-name {
        display: flex;
        align-items: center;
        padding-left: 0;
        flex-grow: 1;
        .name {
          flex-grow: 1;
          color: #224e96;
        }
        div img {
          margin-left: 10px;
        }
      }
    }

    .profile-area {
      position: absolute;
      right: 0;
      top: 102%;
      background: #334e96;
      width: 17%;
      border-radius: 20px;
      z-index: 100;
      .profile-content {
        padding: 10% 15%;
        &::after {
          content: "";
          position: absolute;
          top: 1px;
          left: 60%;
          // left: calc(50% + 4px);
          width: 8px;
          height: 8px;
          background: #224e96;
          transform-origin: 0 0;
          transform: rotate(-45deg);
        }
        .profile-item {
          cursor: pointer;
          margin-bottom: 10%;
          min-width: 100px;
          &:hover {
            opacity: 0.8;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          .logout {
            color: #fff;
          }
        }
      }
      a {
        color: #fff;
      }
    }
  }
}
</style>
