var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-aside-container" }, [
    _c("div", { staticClass: "logo" }, [
      _c(
        "div",
        {
          staticClass: "logo-area",
          on: {
            click: function ($event) {
              return _vm.$router.push({ path: "/dashboard/home" })
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require(`@/assets/img/05-Panda/nihaokids-logo` +
                (_vm.logoIsHover == true ? "-hover" : "") +
                `.svg`),
              title: "nihaoKids",
            },
            on: {
              mouseenter: function ($event) {
                _vm.logoIsHover = true
              },
              mouseleave: function ($event) {
                _vm.logoIsHover = false
              },
            },
          }),
        ]
      ),
    ]),
    _c("div", { staticClass: "family-merber" }, [
      _c(
        "div",
        { staticClass: "family-merber-area" },
        _vm._l(_vm.kidsMember, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "family-merber-single",
              class: { activeKid: _vm.curretnKidIndex === index },
            },
            [
              _c(
                "div",
                {
                  staticClass: "family-merber-avatar",
                  on: {
                    click: function ($event) {
                      return _vm.chooseWhichKid(item, index)
                    },
                  },
                },
                [_c("img", { attrs: { src: item.photo, alt: "" } })]
              ),
              _c("div", { staticClass: "family-merber-name font-menu-small" }, [
                _vm._v(" " + _vm._s(item.firstName) + " "),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "menu" }, [
      _c(
        "div",
        { staticClass: "menu-list font-menu-large" },
        _vm._l(_vm.menuList, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "menu-item" },
            [
              _c("router-link", { attrs: { to: item.url } }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "test-net", on: { click: _vm.openCheckMask } }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/19-Dashboard/icon-mic-small.svg"),
            alt: "",
          },
        }),
        _c("div", [_vm._v("DEVICE CHECK")]),
      ]),
    ]),
    _vm.isOpenDeviceCheck
      ? _c("div", { staticClass: "device-check-mask" }, [
          _c(
            "div",
            { staticClass: "mask-content scaleToAppear" },
            [_c("DeviceCheck", { on: { close: _vm.closeCheckMask } })],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }